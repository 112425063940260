<template>
  <edit-template @confirm="submit" :confirm-loading="loading" flag="edit" :data-loading="dataLoading">
    <el-form :model="form" ref="form" label-width="auto" :rules="rules">
      <el-form-item label="收费方案名称" prop="name">
        <el-input v-model="form.name" placeholder="请输入" :maxlength="20"></el-input>
      </el-form-item>
      <el-row>
        <el-col :span="6" :sm="8" :xs="8">
          <el-form-item label="业务类型" prop="type">
            {{form.type}}
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="缴费方式" prop="mode">
            {{form.mode}}
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="入账校区" prop="school_ids">
        <el-checkbox-group v-model="form.school_ids" @change="changeCheckbox">
          <el-checkbox v-for="item in schools" :label="item.id" :key="item.id" :value="item.id" :disabled="fixedSchool.includes(item.id)">{{item.school_name}}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item prop="combination">
        <span slot="label" style="font-size: 20rem;color: #1E3AA2;">收费计划</span>
<!--        <el-button type="primary" :disabled="form.combination.length>=36" size="large" plain @click="visible=1">添加收费计划</el-button>-->
      </el-form-item>
      <el-form-item label="总金额">
        {{form.total_amount}}
      </el-form-item>
    </el-form>
    <div class="plan">
      <plan class="plan-item" v-for="(item,index) in form.combination" :data="item" :key="index" :handle="false"></plan>
    </div>
  </edit-template>
</template>

<script>
import EditTemplate from "@/components/pages/Edit"
import plan from "./components/plan"
export default {
  _config: {route: {path: "edit", meta: {title: "编辑"}}},
  components: {EditTemplate,plan},
  data() {
    return {
      // 加载状态
      loading: false,
      // 初始数据加载状态
      dataLoading:false,
      // 业务类型
      business:[],
      // 缴费方式
      types:[],
      // 入账校区选项
      schools:[],
      // 收费学年
      grade:[],
      // 收费科目组合
      subjects:[],
      // 收费计划, 临时数据
      plan:{},
      // 编辑索引
      index:-1,
      // 表单数据
      form: {
        name:null,
        school_ids:[],
        combination:[],
        total_amount:0,//总金额
      },
      // 入账校区固定数据
      fixedSchool:[],
      // 表单验证规则
      rules: {
        name:[{required:true,message:"请输入收费方案名称",trigger:"blur"}],
        school_ids:[{required:true,message:"请选择入账校区",trigger:"blur"}],
      },
      pickerOptions:{
        disabledDate(e){
          let now = (new Date()).getFullYear();
          return Math.abs(now - e.getFullYear()) > 3;
        }
      }
    }
  },
  computed:{
    amount(){
      return 10000
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.dataLoading = true;
      this.$_register.get("api/finance/fee-plan/get-check").then(res=>{
        let {school} = res.data.data;
        this.schools = school;
        this.$_register.get("api/finance/fee-plan/show?id="+this.$route.query.id).then(res=>{
          let data= res.data.data;
          data.school_ids = data.school_ids.map(item=>Number(item));
          this.form = data;
          this.fixedSchool = data.school_ids;
        }).finally(()=>this.dataLoading = false);
      }).catch(res=>this.dataLoading=false)

    },
    submit() {
      this.$refs.form.validate(flag=>{

        if (flag){
          this.loading = true;
          let data = {
            id:this.$route.query.id,
            name:this.form.name,
            school_ids:this.form.school_ids
          }
          console.log(this.form,data,"<<<");
          this.$_register.post("/api/finance/fee-plan/update",data).then(res=>{
            this.$message.success(res.data.msg);
            this.$router.back();

          }).finally(()=>this.loading = false);
        }
      })
    },
    changeCheckbox(e){
      console.log(e,this.form.school_ids,"<<<");
    }
  }
}
</script>

<style lang="scss" scoped>
.plan{
  display: grid;
  grid-gap: 48rem;
  grid-template-columns: repeat(4,1fr);
}
</style>
